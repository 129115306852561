<template>
  <div class="box">
    <img @click="picclick" :src="src" />
  </div>
</template>

<script>
export default {
  name: "Img",
  props: {
    src: String,
  },
  methods: {
    picclick() {
      this.$emit("click");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  width: 100%;
  margin: 0;
  padding: 0;
}
.box img {
  width: 100%;
}
</style>
