<template>
  <div style="padding-bottom: 35px">
    <p v-if="type == 'computer'" class="center boxheader">专业取名网站 获数百万宝爸宝妈信赖</p>
    <p v-else-if="type == 'rengong'" class="center boxheader">国学起名大师团 帮你0门槛取好名</p>
    <p v-else class="center boxheader">好评如潮</p>
    <Ratebox v-if="type == 'rengong'" />
    <div class="commentbox">
      <template v-if="type == 'rengong'">
		  <van-row class="row">
			<van-col span="9" class="col">
			  <span class="tag">老师态度好(5.9万)</span>
			</van-col>
			<van-col span="7" class="col">
			  <span class="tag"> 选好了(11.2万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag">家人满意(6.5万)</span>
				&nbsp;&nbsp;
			</van-col>
		  </van-row>
		  <van-row class="row">
			<van-col span="9" class="col">
			  <span class="tag">考虑很周全(8.1万)</span>
			</van-col>
			<van-col span="7" class="col">
			  <span class="tag">很不错(2.5万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag">起名厉害(1.1万)</span>
				&nbsp;&nbsp;
			</van-col>
		  </van-row>
      </template>
      <template v-else-if="type =='computer'">
		  <van-row class="row">
			<van-col span="8" class="col">
			  &nbsp;&nbsp;
			  <span class="tag">名字好听(9.1万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag"> 很不错(3.1万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag">家人满意(1.2万)</span>
				&nbsp;&nbsp;
			</van-col>
		  </van-row>
		  <van-row class="row">
			<van-col span="8" class="col">
				&nbsp;&nbsp;
			  <span class="tag">分析全面(4.1万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag">名字多(2.5万)</span>
			</van-col>
			<van-col span="8" class="col">
			  <span class="tag">非常专业(1.1万)</span>
				&nbsp;&nbsp;
			</van-col>
		  </van-row>
      </template>
	  <template v-else>
		  <van-row class="row">
				<van-col span="8" class="col">
				  <span class="tag">分析详细(10.1万）</span>
				</van-col>
				<van-col span="8" class="col">
				  <span class="tag">很专业(3.1万）&nbsp;&nbsp;&nbsp;&nbsp;</span>
				</van-col>
				<van-col span="8" class="col">
				  <span class="tag">想要改名(1.2万）</span>
				</van-col>
		  </van-row>
		  <van-row class="row">
				<van-col span="8" class="col">
				  <span class="tag">有依据(4.1万）&nbsp;&nbsp;&nbsp;&nbsp;</span>
				</van-col>
				<van-col span="8" class="col">
				  <span class="tag">打分很准(2.5万）</span>
				</van-col>
				<van-col span="8" class="col">
				  <span class="tag">非常正规(1.1万）</span>
				</van-col>
		  </van-row>
	  </template>
     
    </div>
    <div class="commentscrollbox">
      <vue-seamless-scroll
        :data="comments"
        :class-option="defaultOption"
        class="warp"
      >
        <ul class="commentlist">
          <li v-for="(n,index) in comments" >
            <div class="nameheader">
              <img
                :src="n.cv_pic"
                alt=""
              />
              <div style="flex-grow:1">
                <p class="nickname">{{n.cv_name}}</p>
                <p v-if="type == 'computer'" class="time">{{n.cv_time_unit}}</p>
                <div v-else class="time" style="display: flex;  justify-content: space-between; align-items: center;  ">
                  <div style="display: flex; align-items: center;">
                    打分 <van-rate readonly color="#FDA802" size="8" v-model="rate" ></van-rate>
                  </div>
                  <div  v-if="type != 'testname'"  >已购买：孙老师起名套餐</div>
                </div>
              </div>
            </div>
            <p class="comment">
              {{n.content}}
            </p>
            <div class="commentpicbox">
              <template v-for="item in n.pic_content" >
                <img :src="item"  />
              </template> 
              
            </div>
            <div class="commentfooter">
              <span class="lookbox">浏览{{n.cv_num}}次</span>
              <div  v-if="n.like ==1 " class="likebox">
                <img src="http://cdn1.zhiyileiju.cn/dianzan_red.png" alt="" />
                <span  style="color:#b95353">{{n.cv_rate_num}}</span>
              </div>
              
              <div  @click="zan(n.id,index)"  v-else class="likebox">
                <img src="http://cdn1.zhiyileiju.cn/zan.png" alt="" />
                <span>{{n.cv_rate_num}}</span>
              </div>
            </div>
    
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>


<script>
import vueSeamlessScroll from "vue-seamless-scroll";

import axios from "../utils/request";
import Ratebox from "./Rate.vue"
import { Rate } from 'vant';
import {Toast} from "vant";

export default {
  name: "Comment",
  props: {
    customstyle: String,
    type:{
      type:String,
      default:"computer"
    }
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.comments.length,
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  data() {
    return {
      comments: [
        {
          id: 1,
          content: "Dolores cupiditate libero magni voluptatibus error.",
          pic_content: "https://lorempixel.com/640/480/?49622",
          cv_pic: "https://lorempixel.com/640/480/?27353",
          cv_name: "白猫风中",
          cv_type: "1",
          cv_num: "432160",
          cv_time_unit: "1年前",
          cv_rate_num: "1117",
        },
      ],
      rate:5,
      page: 1,
    };
  },
  components: {
    vueSeamlessScroll,
    vanRate:Rate,
    Ratebox
  },
  async created() {
    await this.initdata();
  },
  methods: {
    async initdata() {
      this.getcomments();
    },

    async getcomments() {
      let ctype = this.type == 'rengong'? 2: 1;

      let postdata = {
        type: 1,
        perPage: 10,
        ctype,
        page: this.page,
      }
      if(this.type == 'testname'){
        postdata.atype = 1
      }
      let data = await axios.post("/ekaf",postdata );
      this.comments = data.data.data;
      if (data.data.meta.total < (this.page + 1) * 10) {
        this.page = this.page + 1;
      } else {
        this.page = 1;
      }
    },

    async zan(id,index){

      axios.post('/ekaf/fcommitRate',{
        id
      })
      Toast.success("点赞成功")
      this.comments[index].like = 1
      this.comments[index].cv_rate_num = this.comments[index].cv_rate_num + 1
    }
  },
};
</script>

<style scoped>
.tag {
  display: inline-block;
  border: 1px solid #b95353;
  border-radius: 5px;
  font-size: 12px;
  color: #b95353;
  padding: 7px 4px;
  margin-bottom: 10px;
}


.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 3px;
}
.boxheader{
  color: #b95353;
  font-weight: bold;
  font-size: 18px;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.commentbox{

  /* display: flex;
  flex-wrap: wrap;
  align-items: center; */
 
}

.commentlist {
  padding: 20px;
}

.commentscrollbox {
  overflow: hidden;
  height: 600px;

}

.commentlist li {
  border-bottom: 1px #979797 dotted;
  margin-bottom: 10px;
}

.nameheader {
  display: flex;
}
.nameheader img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.nameheader p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #020202;
}

.nameheader .time {
  font-size: 12px;
  color: #979797;
}

.commentlist .comment {
  font-size: 14px;
  color: #525252;
  line-height: 1.4em;
}

.commentpicbox {
  display: flex;
  flex-wrap: nowrap;
}

.commentpicbox img {
  width: 22vw;
  height: 22vw;
  border-radius: 8px;
  margin-right: 14px;
}

.commentfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 12px;
}

.likebox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.likebox img {
  width: 13px;
  margin-right: 5px;
}

.lookbox {
  color: #979797;
}

.fixbutton {
  position: fixed;
  bottom: 0;
}
</style>