<template>
	<div class="index index___" id="agentTitle">
		<ExistingOrderTips>
            <template slot-scope="slotProps" slot="big-title-text">亲爱的{{ slotProps.userInfoData.xname }}宝宝</template>
            <template v-slot:small-title-text><div class="ExistingOrderTips_text_size">您专属起名报告已生成</div></template>
        </ExistingOrderTips>
		<Imgbox src="https://img.nnabc.cn/202312271718.jpg" />
		<Card class="formcard">
			<!-- <div v-if="!showCopyright"
				style="font-size:18px;font-weight:bold;text-align:center;background:#fff;height:60px;line-height:100px">
				<span v-if="isks4">好名文化起名</span>
				<span v-else>诸葛国学起名网</span>
			</div> -->
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入宝宝姓氏"
					:rules="[{ validator }]">
					<template #label>
						<div class="labelclass">
							姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;氏
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="status">
					<template #label>
						<div class="labelclass">出生状态</div>
					</template>
					<template #input>
						<van-radio-group v-model="status" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">已出生</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">未出生</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field size="mid" name="sex">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
							<van-radio name="3" icon-size="18" v-if="status == '2'" checked-color="#B95353">未知
							</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span @click="pickdata">{{boyStr ? boyStr : label }}</span>
					</template>
				</van-field>
				<van-field size="mid" v-model="Form.bit" type="text" name="bit" label="bit" placeholder="如字辈或喜用字">
					<template #label>
						<div class="lastlabel">定字<span>(可不填)</span></div>
					</template>
					<template #extra>
						<div @click="showBitPicker">{{ bittype }}</div>
					</template>
				</van-field>
				<!-- <div v-if="demand1" style="font-size: 12px;margin-left:16px;">个人信息声明</div> -->
				<div   class="tip">-仅需9 s 取个好名字 陪伴宝宝一辈子-</div>
				<!-- 表单提交 -->
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_index_top_form')" native-type="submit">
						点击获取200个吉祥美名</van-button>
				</div>

				<div v-if="!needxieyi" class="center tip">
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					提交即视为同意收集相关信息，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人信息保护政策</span>
				</div>

				<div class="tip" v-if="aaa">测试部分免费，获取报告或解锁全部内容时，需付费查看 </div>

				<div  class="center tip"  v-if="!demand1 && !demand2">
					已为<span class="color-red" style="font-weight:bold">&nbsp;6,371,918&nbsp;</span>新生儿起名，好评满意率达99.8％
				</div>


				<div  class="actionScroll" v-if="!demand1">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" class="warp">
						<ul class="scrollbox">
							<li class="scrollitem" v-for="(item, index) in listData" :key="index">
								<span class="title1" v-text="item.time_unit"></span>
								<span class="region123" style="width: 40px;" v-text="item.city"></span>
								<span class="name1" v-text="item.uname"></span>
								<span class="date" v-text="item.action"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</van-form>
		</Card>

		<Card class="img">
			<Imgbox src="http://cdn1.zhiyileiju.cn/qumingtese.jpg"></Imgbox>
		</Card>

		<!-- 九大维度 -->
		<template v-if="showCopyright">
			<Card class="img">
				<Imgbox src="https://img.nnabc.cn/WechatIMG2847.png"></Imgbox>
			</Card>
		</template>

		<!-- 重要性 -->
		<Card v-if="showCopyright" class="img">
			<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG67.jpeg"></Imgbox>
		</Card>

		<Card v-if="!bjkspenging" class="img">
			<Imgbox src="http://cdn1.zhiyileiju.cn/mingzihaohuai.jpg"></Imgbox>
		</Card>

		<Card v-if="!bjkspenging " class="img">
			<imgbox   src="http://cdn1.zhiyileiju.cn/WechatIMG690.jpeg" />
		</Card>

		<!-- 选择我们 -->
		<Card v-if="showCopyright && !zylj && !qdww" class="img">
			<!-- <imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG65.jpeg" /> -->
			<imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG375.jpeg" />
		</Card>

		<Card v-if="!bjkspenging && !demand1 " class="img">
			<Comment />
		</Card>

		<Card class="img formcard">
			<div class="center boxheader" style="margin:10px 0">请填写信息起名</div>
			<van-form @submit="onSubmit" size="mid" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="large" name="username" placeholder="请输入宝宝姓氏"
					:rules="[{ validator, }]">
					<template #label>
						<div class="labelclass">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;氏</div>
					</template>
				</van-field>
				<van-field name="status" size="large">
					<template #label>
						<div class="labelclass">出生状态</div>
					</template>
					<template #input>
						<van-radio-group v-model="status" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">已出生</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">未出生</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="sex" size="mid">
					<template #label>
						<div class="labelclass">性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
							<van-radio name="3" icon-size="18" v-if="status == '2'" checked-color="#B95353">未知
							</van-radio>
						</van-radio-group>
					</template>
				</van-field>

				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true }]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span @click="pickdata">{{boyStr ? boyStr : label }}</span>
					</template>
				</van-field>
				<van-field size="mid" v-model="Form.bit" type="text" name="bit" label="bit" placeholder="如字辈或喜用字">
					<template #label>
						<div class="lastlabel">定字<span>(可不填)</span></div>
					</template>
					<template #extra>
						<div @click="showBitPicker">{{ bittype }}</div>
					</template>
				</van-field>
				<div class="tip">-仅需9 s 取个好名字 陪伴宝宝一辈子-</div>
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_index_bottom_form')" native-type="submit">
						点击获取200个吉祥美名</van-button>
				</div>
				<div v-if="!needxieyi" class="center tip">
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					提交即视为同意收集相关信息，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人信息保护政策</span>
				</div>
			</van-form>
		</Card>

		<div v-html="this.price.copyright" style="text-align:center;font-size:.2rem; margin-top:.2rem;margin-bottom:1rem">

		</div>
		
		<!-- <div v-else  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   湖南亮亮文化传媒有限公司</div>
			<div>地址：长沙市开福区月湖街道鸭子铺路1号1房2室</div>
			<div>电话：18975441215</div>
		</div> -->
		
		<div v-if="!zxaaaa" class="webfooter" style="margin-top: -80px;font-size:10px;color:#323232">
			<!-- <a @click="ICPCallback"></div> -->
			<a href="https://beian.miit.gov.cn/" style="color:#d5612b"><span style="color:#323232"></span>{{put_records}}</a>
		</div>
		

		<van-button :class="fixedbtn == true ? 'isFixed' : ''" size="large" color="#B95353" type="info"
			native-type="submit" @click="bottombtnclick">
			立即获取美名
		</van-button>

		<!-- 日期选择器 -->
		<rui-datepick :date="currentDate" v-model="boyShow" @confirm="boyConfirm" />
		<!-- 定字选择器 -->
		<van-popup v-model="showPicker" position="bottom">

			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onBitConfirm" />
		</van-popup>

		<!-- 红包弹窗 -->
		<!-- <van-popup class="redpacket" v-model="showredPacket" v-if="false"> -->
		<van-popup class="redpacket" v-model="showredPacket" >
			<div class="redpacketbox">
				<div class="center redtitile">恭喜获得红包！</div>
				<div class="center dicsountnum" style="line-height:1em;align-items: flex-end;font-size:40px;color:red;font-weight:500;margin-top:18px">
					￥{{price.discount_amount}}
					<span style="margin-left:5px;line-height:1.5em;font-size:18px">元</span>
				</div>
				<div class="center" style="margin-top:26px;font-size:14px;color:#C37D3F">使用可半价查看200个甄选美名</div>
				<div class="countbox center">
					<van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
						</template>	
					</van-count-down>
					<span style="color:#fff;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
				</div>
				<van-button class="redbtn" @click="buy" round>点击立即使用</van-button>
				<!-- <van-button class="redbtn redbtn_2"  color="url('http://cdn1.zhiyileiju.cn/1701634698138_.pic_hd.jpg') no-repeat center" icon="http://cdn1.zhiyileiju.cn/1691634697717_.pic_hd.jpg" @click="redPackedCallback"   round>直接名师咨询</van-button> -->
			</div>	
		</van-popup>

		<!-- 订单确认弹框 -->
		<van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <!-- <div class="blockbx">
                    <div class="tit">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，点击查看名字</van-button>
                    </div>
                    <div class="unpay" @click="shopPayConfirm = false">
                        未支付，我再考虑一下
                    </div>
                </div> -->
				<div class="block">
                    <div class="title">
                        <p class="top_title_">离宝宝好名报告只差1步</p>
                        <p class="bottom_title_" style="margin-top:-9px;">支付后查看200个美名</p>
                    </div>
                    <div class="bg_box_chunk_">
                            <img :src="buttom_top_src_img" alt="">
                    </div>
                    <div class="payed" style="margin-top: 11%;" @click="getpayurls('','')">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG600.jpeg" alt="">
                    </div>
                    <!-- <div class="payed" @click="unpay">
                        <img :src="buttom_src_img"  alt="">

                    </div> -->
                    <div class="payed"  @click="Dcheckorder">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG605.png" alt="">
                    </div>
                    <div class="countbox center">
                        <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            </template>	
                        </van-count-down>
                        <span style="color:#525252;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
                    </div>
                </div>
            </div>
        </van-overlay>

		<!-- 隐私协议 -->
		<van-popup v-model="lookrules" style="width:90%;max-height:500px;" closeable>
			<div style="padding:20px;padding-top:20px">
				<h1 class="center">用户隐私协议</h1>
				尊敬的用户，欢迎阅读本协议：<br/><br/>

{{CopyRight}}依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的{{CopyRight}}服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/><br/>

用户在申请{{CopyRight}}服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。<br/><br/>

保护用户个人信息是{{CopyRight}}的一项基本原则，{{CopyRight}}运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可{{CopyRight}}不会向第三方（{{CopyRight}}控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。<br/><br/>

在用户发送信息的过程中和本网站收到信息后，本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。<br/><br/>

此外，您已知悉并同意：在现行法律法规允许的范围内，{{CopyRight}}科技可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用{{CopyRight}}科技服务或产品的情况所认为您可能会感兴趣的信息。<br/><br/>

本网站有权在必要时修改服务条例，本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。<br/><br/>

如果您还有其他问题和建议，可以通过电子邮件673681507@qq.com或者电话17620368211联系我们。

{{CopyRight}}保留对本协议的最终解释权。
		<van-button class="redbtn" @click="lookrules=false" round>关闭</van-button>
</div>
		</van-popup>
		<!--  投诉 -->
		<span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
			<img style="width:100%" src="http://cdn1.zhiyileiju.cn/WechatIMG559.pic" alt="">
		</span>

	
	</div>
</template>

<script>
	import {
		RadioGroup,
		Radio,
		Form,
		Field,
		Toast,
		Picker,
		Popup,
		Tag,
		CountDown,
		Overlay,
		Checkbox
	} from "vant";

	import Imgbox from "../components/ImageBox.vue";
	import Card from "../components/Card.vue";
	import ruiDatepick from "../components/datepick/index.vue";
	import countTo from "vue-count-to";
	import Comment from "../components/Comment.vue";
	import vueSeamlessScroll from "vue-seamless-scroll";
	import router from "../router";
	import report from "../utils/stat";
	import ExistingOrderTips from "../components/ExistingOrderTips/index.vue"

	import axios from "../utils/request";

	export default {
		name: "Home",
		components: {
			Imgbox,
			Card,
			vanForm: Form,
			vanField: Field,
			vanRadioGroup: RadioGroup,
			vanRadio: Radio,
			ruiDatepick,
			vanPicker: Picker,
			vanPopup: Popup,
			countTo,
			vanTag: Tag,
			vanOverlay: Overlay,
			Comment,
			vueSeamlessScroll,
			vanCountDown:CountDown,
			vanCheckbox:Checkbox,
			ExistingOrderTips
		},
		computed: {
			is_weixn() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			},
			defaultOption() {
				return {
					
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: this.listData.length,
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
			dialogwidth() {
            	return window.innerWidth * 0.9;
        	},
			showCopyright() {
				// let from = this.$route.query.from;
				// let allowfrom = ['a1','a2','a6','a7', 'ab1','a9', 'b1','c1','d1','ac1','k1','k2','ad1','k3','f1'];
				// if (allowfrom.indexOf(from) > -1) {
				// 	return true
				// } else {
				// 	return false
				// }
				return true;
			},
			needxieyi(){
				let from = this.$route.query.from;
				// let allowfrom = ['a1','a2', 'a6', 'a7','ab1','a9','ad1','ac1','k1','k2','k3','d1','d2','ae1','k31',"k35",'k50','k51','k52'];
				let allowfrom = [];
				if (allowfrom.indexOf(from) > -1) {
					this.checked = false
					return true
				} else {
					return false
				}
        	},
			
			showOvCopyright() {
				let from = this.$route.query.from;
				// let allowfrom = ['b1', 'b2', 'b3', 'b4'];
				let allowfrom = ['b2', 'b3', 'b4'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isbjks(){
				let from = this.$route.query.from;
				let allowfrom = ['k1', 'k2', 'k3', 'k4','k9','k11','k13','k15','r1','k32'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			aaa(){
				let from = this.$route.query.from;
				let allowfrom = ['k38','k40'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			// 北京快手审核期，隐藏部分内容
			bjkspenging(){
				let from = this.$route.query.from;
					let allowfrom = ['f2', 'k4'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},

			// f渠道信息

			ifFqudao(){
				let from = this.$route.query.from;
					let allowfrom = ['f1', 'f2','f3','f5','f7','f9'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			bah(){
				let from = this.$route.query.from;
					let allowfrom = [''];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			sxhjzn(){
				let from = this.$route.query.from;
					let allowfrom = ['bdqm1','bdqm3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}	
			},
			sxhjkj(){
				let from = this.$route.query.from;
					let allowfrom = ['bdqm4','bdqm5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}	
			},
			sxhjznqdh(){ //去掉电话和地址
				let from = this.$route.query.from;
					let allowfrom = ['bdqm3'];
				if (allowfrom.indexOf(from) > -1) {
					return false
				} else {
					return true
				}	
			},

			qdww(){
				let from = this.$route.query.from;
					let allowfrom = ['p1','p2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			cqlsshfw(){
				let from = this.$route.query.from;
					let allowfrom = ['k40','k61'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			tjjxkj(){
				let from = this.$route.query.from;
					let allowfrom = ['z1','g3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			zylj(){
				let from = this.$route.query.from;
					let allowfrom = ['q1','b1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},

			zxaaaa() {
				let from = this.$route.query.from;
				let allowfrom = ['g1','r1','z1','bdqm4','k38','k39','k40','k41'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					let allowfrom1 = ['k31','k35','k50','k51','k52'];
					if (allowfrom1.indexOf(from) > -1) {
						this.put_records = "沪ICP备19000880号-2"
						return false
					} 
					// return false
				}
			},
			
			isks4(){
				let from = this.$route.query.from;
				let allowfrom = ['d1', 'd2', 'd3', 'd4','d5','d7','d9','d11','qs1','qs2','qs3'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			nbkrjy() {
				let from = this.$route.query.from;
				let allowfrom = ['n1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			demand1() {
				let from = this.$route.query.from;
				let allowfrom = ['k31','k35','k50','k51','k52'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			demand2() {
				let from = this.$route.query.from;
				let allowfrom = ['k50','k51','k52'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			nohnllwh() {
				let from = this.$route.query.from;
				let allowfrom = ['k31','k35','k50','k51','k52'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			wcqxhqmg() {//潍城区玄海起名馆
				let from = this.$route.query.from;
				let allowfrom = ['k38','k39','k41'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
		},
		watch: {
			status(a, b) { //深度监听，可监听到对象、数组的变化
				if (a == 2) {
					this.Form.sex = "3"
					this.label = '请选择预产期'
				} else {
					this.Form.sex = "1"
					this.label = '请选择出生时间'
				}
			}
		},
		async mounted() {
			if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}
			//  window.history.pushState(null, document.URL);
			// // 给window添加一个popstate事件，拦截返回键，执行this.onBrowserBack事件，addEventListener需要指向一个方法
			// window.addEventListener("popstate", this.goBack, false);
			
			window.addEventListener("scroll", this.handleScroll);
			window.localStorage.setItem('fromk', this.$route.query.from);
			report("v1_index");
			if(this.$route.query.from == 'm1'){
				window.localStorage.setItem('query', JSON.stringify(this.$route.query));
			}
		},
		data() {
			let currentDate = new Date();
			let orderid = sessionStorage.getItem('orderid')
			let paysuccess = sessionStorage.getItem('paysuccess')
			return {
				put_records:'',
				buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG607.png',
            	buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG608.jpeg',
				complaint_buttom_fal:true,//投诉开关
				showredPacket: (orderid && !paysuccess) ? true :false,
				shopPayConfirm:false,
				// shopPayConfirm:true,
				lookrules:false,
				checked:false, //隐私协议选中状态
				radio: 1,
				boyShow: false,
				showPicker: false,
				bottom_btn_clicked: false,
				counttime:10*60*1000,
				comments: [1, 2, 3, 4, 5, 6, 7, 8, 98, 10],
				label: "请选择出生时间",
				currentDate: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate(),
				listData: [{
					action: "正在填写出生日期",
					city: "怒江傈僳族自治州",
					created_at: "2021-07-05 12:06:50",
					id: 1,
					time_unit: "56秒前",
					uname: "矮小扯小蚂蚁",
					updated_at: "2021-07-05 12:06:50",
				}, ],
				bittype: "中间字",
				boyStr: "",
				columns: ["中间字", "尾部字"],
				startVal: 635000,
				endVal: 6371918,
				fixedbtn: false,
				status: "1",
				Form: {
					username: "",
					status: "1",
					sex: "1",
					bit: "",
				},
				price:[],
				orderinfo:{},
				CopyRight:''
			};
		},
		async created() {
		
			window.localStorage.removeItem('qm_red_packet_type');
			let data = await this.getactives();
			this.listData = data.data;
			if (this.$route.query.uniqueKey) {
				window.localStorage.setItem("uniqueKey", this.$route.query.uniqueKey)
			}

			if (this.$route.query.uctrackid) {
				window.localStorage.setItem("uctrackid", this.$route.query.uctrackid)
			}


			if(this.isks4){
				document.title = "好名文化起名";
			}
			let from = this.$route.query.from;
			if(from.startsWith('a')){
				document.title = "宝宝起名"
			}
			this.getPrice();
		},
		destroyed() {
			this.timer && clearTimeout(this.timer);
		},
		methods: {
			 goBack () {//后退事件
				if (  window.sessionStorage.getItem('hy_return') === null ) {
					
					let from = this.$route.query.from;
					let allowfrom = ['ab1','ae1','p1','g1'];
					if (allowfrom.indexOf(from) > -1) {
						window.history.pushState(null, document.URL);
						window.location.href = 'https://huyin.zhiyileiju.cn/shopping?from=xm6'
						window.sessionStorage.removeItem('orderid');
						window.sessionStorage.setItem('hy_return',1);
					} else {
						this.$router.go(-1)
					}
				
					
				}else{
					this.$router.go(-1)
				}

			
            // console.log("点击了浏览器的返回按钮");
            // this.$router.go(0)
            // this.$router.go(-1)
            // sessionStorage.clear();
            // window.history.back();
        },
		async getWxConfig() {
				let url = window.location.href;

			let data = await axios.post("/payment/wechatconf", {
				url
			})
			return data.data.data.data;
		},
			pickdata() {
				this.boyShow = true;
			},
			complaintButtom() {
				window.location.href = 'https://work.weixin.qq.com/kfid/kfca7224cd2c75fff38'
        	}   ,
			async getPrice(){
				let price = await axios.get(`/getSalesPrice?fromk=${this.$route.query.from}`);
				this.price = price.data.data[0] || [];
				this.CopyRight = price.data.data[0].company_name
			},
			async buy(){
				window.localStorage.setItem('qm_red_packet_type',1);
				report("v1_redpacket_click");
				await this.confirmOrder();
				 if ( this.is_weixn ) {
					setInterval( ()=> {this.checkorder(sessionStorage.getItem('orderid'), 2);}, 2000)
					
				}
				let t = this;
				setTimeout(async function(){
					await t.checkorder(sessionStorage.getItem('orderid'), 2);
				},2000)
				
				this.showredPacket = false;
				this.shopPayConfirm = true;
				if (this.is_weixn) {
                    let payinfo = await axios.get(
                        `https://api.zhiyileiju.cn/api/v1/payment/${'wechat'}/${sessionStorage.getItem('orderid')}/wechat`)
                    let pay = payinfo.data.data;
                    console.log('res',pay)
                    wx.ready(function() {
                        wx.chooseWXPay({
                            timestamp: pay.timeStamp,
                            nonceStr: pay.nonceStr,
                            package: pay.package,
                            signType: pay.signType,
                            paySign: pay.paySign, // 支付签名
                            success: function(res) {
                                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                    router.replace({
                                        name: "paysuccess",
                                        params: { id:sessionStorage.getItem('orderid'), type:2 }
                                    }).catch(() => {});
                                }else{
                                    router.replace({
                                        name: "testresult",
                                        params: { id:sessionStorage.getItem('orderid'), type:2 }
                                    }).catch(() => {});
                                }
                            }
                        });
                    });
                }else{
					location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${sessionStorage.getItem('orderid')}`;
				}
			},
			ICPCallback(){
				location.href = `https://beian.miit.gov.cn/`;
			},
			getpayurls () { //弹框立即付款
				// report("v1_pay_immediate_payment");
				this.buy()
			},
			unpay(){
            // report("v1_pay_account_paid");
            // router.back();
				
					location.href = "weixin://dl/business/?t=dqnSN5t1Ehe";
           
        	},
			async confirmOrder() {
				try {
					let aa = await axios.post("/order/confirm", {
						order_id: sessionStorage.getItem('orderid'),
						paytype: 2,
						discount_id: 1
					});
				} catch (err) {}
			},
			checkorder(id, type) {
				this.timer = setInterval(async () => {
					try {
						let data = await axios.post("/payment/query", {
							paytype: type,
							order_id: id,
						});
						if (data.data.data.is_pay) {
							router.replace({
								name: "paysuccess",
								params: { id, type: data.data.data.type },
							}).catch(() => {});
						}
					} catch (err) {}
				}, 5000);
			},
		    async mcheckorder() {
				let data = await axios.post("/payment/query", {
					paytype: 2,
					order_id: sessionStorage.getItem('orderid'),
				});
				if (data.data.data.is_pay) {
					router.replace({
						name: "paysuccess",
						params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
					}).catch(() => {});
				} else {
					Toast.fail("当前订单未支付");
				}
			},
			async Dcheckorder() {
				// try {
				// 	this.mcheckorder();
				// } catch (err) {
				// 	Toast.fail("当前订单未支付");
				// }

				 try {
					this.mcheckorder();
					let data = await axios.post("/payment/query", {
						paytype: 2,
						order_id:  sessionStorage.getItem('orderid'),
					});
					if (data.data.data.is_pay) {
						sessionStorage.setItem('paysuccess','1');
						router.replace({
							name: "paysuccess",
							params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
						}).catch(() => {});
					} else {
						this.buy()
					// Toast.fail("当前订单未支付");
					}

				} catch (err) {
					this.buy()
					// Toast.fail("当前订单未支付");
				}
			},
			async getactives() {
				let data = await axios.post("/ekaf", {
					type: 3,
					perPage: 10,
					page: 1,
				});
				return data.data;
			},
			boyConfirm(data) {
				this.birthdata = data;
				this.boyStr = data.thisStr;
			},
			valifail(errinfo) {
				let errors = errinfo.errors[0]
				if (errors.name == "username") {
					Toast('姓氏填写不正确')
				} else {
					Toast('出生日期填写不正确')
				}
			},
			showBitPicker() {
				this.showPicker = true;
			},
			validator(val) {
				if (val.length > 0 && val.length < 3) {
					return true;
				} else {
					return false;
				}
			},
			async onSubmit() {
				if ( this.checked !== true ) {
					this.$dialog.confirm({
						title: '提示',
						message: '请先同意隐私协议',
						confirmButtonText:'同意'
					})
					.then(() => {
						this.checked = true
						// this.submitContentCallback()
					})
					.catch(() => {
						// on cancel
					});
				}else{
					window.localStorage.removeItem('qm_red_packet_type');
					let birth;
					// if(this.Form.username.length >1){
					//   Toast.fail("当前不支持双姓取名")
					//   return;
					// }
					if (this.birthdata.hour) {
						birth =
							this.birthdata.year +
							"-" +
							this.birthdata.month +
							"-" +
							this.birthdata.day +
							" " +
							this.birthdata.hour +
							":00:00";
					} else {
						birth =
							this.birthdata.year +
							"-" +
							this.birthdata.month +
							"-" +
							this.birthdata.day +
							" 00:00:00";
					}
					let postdata = {
						birth_state: this.status,
						xname: this.Form.username,
						sex: this.Form.sex,
						pub_birth_date: birth,
						order_type: 1,
						fromk: this.$route.query.from || 10,
						needwdtype: this.bittype == "中间字" ? 1 : 2,
						pageUrl: window.location.href,
						openid:this.$route.query.openid === undefined ? '' : this.$route.query.openid,
						kf:this.$route.query.greenkf === undefined ? '' : this.$route.query.greenkf, 
					};
	
					if (this.Form.bit) postdata.need_word = this.Form.bit;
	
					if (this.status == 1) {
						postdata.pub_birth_date = birth;
					} else {
						postdata.pub_prebirth_date = birth;
					}
					Toast.loading({
						message: '请稍后...',
						forbidClick: true,
					});
					let order = await axios.post("/addOrder", postdata);
					Toast.clear();
					router.push({
						name: "pay",
						params: {
							id: order.data.data.id,
							type: 10
						},
					}).catch(() => {});
				}


			},
			onBitConfirm(value) {
				this.bittype = value;
				this.Form.bittype = value == "中间字" ? 1 : 2;
				this.showPicker = false;
			},
			handleScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 300) {
					this.fixedbtn = true;
				} else {
					this.fixedbtn = false;
				}
			},
			bottombtnclick() {
				document
					.getElementById("agentTitle")
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});
				this.bottom_btn_clicked = true;
				report('v1_index_b_btn');
			},

			payclick(type) {
				if (type == 'v1_index_top_form' && this.bottom_btn_clicked) {
					report('v1_index_b_top_form');
					return;
				}
				report(type);
			},
			redPackedCallback () { //老师直接咨询
				report('v1_index_redpack_t');
				// location.href = "weixin://dl/business/?t=XktlC4wKEPr";
				// location.href = "weixin://dl/business/?t=dqnSN5t1Ehe";
				location.href = "/teacher3/1";
			}	
		},
	};
</script>

<style scoped>
@import "../style/main.css";
.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}


.wrapper .block {
    width: 70%;
    height: 60vh;
    background-color: white;
    border-radius: 16px;
	padding-bottom: .6rem;
}
.wrapper .block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    margin-top: 22px;
    margin-bottom: 17px;
}
.wrapper .block .title p{
    color: #CF031A;
    /* line-height: 44px; */
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}
.wrapper .bg_box_chunk_{
    overflow: hidden;
}
/* .bg_box_chunk_ */
.wrapper img{ 
    width: 100%;
}

.wrapper .countbox{
		margin-top:4%;
	}

	.wrapper .colon {
        display: inline-block;
        margin: 0 4px;
        color: #d12229;
      }
      .wrapper .block_ {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #d12229;
      }
        



.wrapper .Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}



.wrapper .block .payed {
    width: 82%;
    margin: 0 auto;
    margin-top: 3%;
}

.wrapper .unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}


/* =-===== */





.blockbx {
    width: 70%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.blockbx .tit {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.blockbx .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}


	.redpacket.van-popup {
		background: none;
	}

	.redpacketbox{
		width: 75vw;
		/* height: 108vw; */
		max-width: 300px;
		/* max-height: 432px; */
		max-height: 480px;
		padding-top: 71px;
		box-sizing: border-box;
		/* background: url('http://cdn1.zhiyileiju.cn/WechatIMG82.png') no-repeat center; */
		background: url('http://cdn1.zhiyileiju.cn/1681634696587_.pic_hd.jpg') no-repeat center;
		background-size: 100%;
		padding-bottom: 22%;
	}

	.redbtn{
		/* width: 40vw; */
		width: 76%;
		height: 45px;
		display: block;
		margin: 0 auto;
		margin-top: 20px;
		background: #FEC995;
		border: none;
		color: #C41713;
		font-size: 18px;
		font-weight: 500;
	}
	.redbtn_2{
		color: #33673b !important;
		text-indent: 16px;
	}

	.redtitile{
		color: #E38000;
		font-weight: bold;
		font-size: 18px;
	}

	.countbox{
		margin-top:80px;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #d12229;
      }
	.webfooter {
		background-color: #fdf9ea!important;
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		background: white;
		line-height: 2em;
		font-size: 14px;
		/* background: url("http://cdn1.zhiyileiju.cn/kexin.png") no-repeat top;
		background-size: 250px;
		background-color: #fff; */
	}

	.navboxs{
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
		background: #b95353;
		color: #fff;
		position: fixed;
		bottom: 0;
		height: 60px;
	}

	.navite{
		display: inline-block;
		width:50%;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		border-right: 1px solid #fff;
	}
	.navite:last-child{
		border-right: none;
	}

	#agentTitle {
		scroll-behavior: smooth;
	}



	.van-field__label {
		height: 2em;
		line-height: 2em;
	}

	.van-field__control {
		height: 2em;
		line-height: 2em;
	}

	.lastlabel {
		color: rgb(50, 50, 50);
	}

	.lastlabel span {
		font-size: 9px;
		color: #333333;
		font-weight: normal;
	}

	.van-cell--mid {
		padding: 12px 16px;
	}

	.subbutton {
		width: 280px;
		font-size: 18px;
		animation: scaleDrew 1.5s ease-in-out infinite;
	}


	.van-cell {
		font-size: 16px;
	}

	.boxheader {
		font-size: 18px;
		font-weight: bold;
		color: #b95353;
	}

	.subheader {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
	}

	.gdname {
		color: #b95353;
		margin-right: 25px;
	}

	.bdname {
		color: #979797;
		margin-left: 25px;
	}

	.subheader .subimg {
		width: 120px;
	}

	.vsli {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	.vsli p {
		margin: 0;
		padding: 0;
	}

	.vsli .top1,
	.vsli .top2 {
		margin-top: 10px;
		line-height: 2.2em;
		padding-left: 8px;
	}

	.vsli .subtop1,
	.vsli .subtop2 {
		padding-left: 8px;
	}

	.vsli .subtop1 {
		color: #b95353;
	}


	.vsitem {
		font-size: 9px;
		font-weight: bold;
		width: 37vw;
		height: 130px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.vsitem.gditem {
		border: 1px solid #b95353;
	}

	.vsitem.bditem {
		border: 1px solid #979797;
		color: #979797;
	}

	.vsitem .sub1footer,
	.vsitem .sub2footer {
		display: flex;
		align-items: center;
		height: 36px;
		padding-left: 8px;
	}

	.sub1footer {
		background: #b95353;
		color: white;
	}

	.sub2footer {
		background: #979797;
		color: white;
	}

	.vstype {
		width: 20px;
		height: 20px;
		font-size: 12px;
		font-weight: bold;
		background: #b95353;
		color: white;
		margin: 0 7px;
	}
	.synopsis{
		padding:14px 4%;
	}
	  /* //投诉 */
        .fixation_complaint{
            position: fixed;width: 25px;top: 133px;right: 0;
        }
</style>

<style scoped>
.index___ .van-button__icon {
	font-size: 2em;
    line-height: inherit;
    position: absolute;
    left: -.2em;
}

</style>
