
import axios from "./request";

import router from "../router"

function os(){
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    return 1;
  }
  if (isIOS) {
    return 2;
  }
  return 10;
}

function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];
              }
       }
       return(false);
}

function report(type,fromk){
    axios.post("/stat/cli",{
        type,
        platform:os(),
        fromk: window.localStorage.getItem('fromk')
    })
}

export default report