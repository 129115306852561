import axios from 'axios'

var baseURL_;
 var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
      baseURL_ = 'https://hfdjh.htianwh168.com/api/v1';
      // return true;
  } else {
      baseURL_ = 'https://api.zhiyileiju.cn/api/v1';
      // return false;
  }
const service = axios.create({
  // baseURL: "https://api.zhiyileiju.cn/api/v1",
  // baseURL: "http://jhgdr.htianwh168.com/api/v1",
  baseURL: baseURL_,
  timeout: 5000,
  withCredentials: false
})

export default service