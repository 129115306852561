import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./utils/rem"


// import "amfe-flexible"; 
import Card from "./components/Card.vue";
import Imgbox from "./components/ImageBox.vue";
import { Collapse, CollapseItem,Popup,Checkbox, CheckboxGroup,CountDown,Cell, CellGroup, Button,Form,Field,RadioGroup, Radio,Picker, Swipe, SwipeItem,Toast,Rate,Overlay,Loading, Col,Row,Dialog,NumberKeyboard } from 'vant';



Vue.component('Card', Card)
Vue.component('Imgbox', Imgbox)
Vue.component('vanCol', Col)
Vue.component('vanRow', Row)
Vue.component('vanButton', Button)
Vue.component('vanCollapse', Collapse)
Vue.component('vanCollapseItem', CollapseItem)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Rate);
Vue.use(Row);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(CountDown);
Vue.use(NumberKeyboard);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
