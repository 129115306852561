<template>
    <div v-if="userInfoData" class="fixed_strata_externum-chunk---">
        <div class="fixed_top_order_chunk-2" v-if="is_existing_order_tips">
            <div class="text-chunk">
                <slot name="big-title-text" v-bind:userInfoData="userInfoData">{{ userInfoData }}</slot>
                <slot name="small-title-text"></slot>
            </div>
            <button class="button" @click="checkOrderButton">点击查看</button>
        </div>
    </div>
</template>

<script>
import axios from "../../utils/request";
import { Toast } from "vant";
// import { $usersGetOrderInfo } from "../../api/home_marriage_test"
// import isPayment from "../../utils/isPayment"
// import commonPayment from "../../utils/commonPayment"
export default {
    components:{Toast},
    data(){
        return {
            is_existing_order_tips:true,
            userInfoData:null
        }
    },
    computed:{
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
    },
    created () {
        this.init ()
    },


    methods:{ 
        init () {
            this.dataRequest()
        },
        dataRequest() {
            axios({
                method:'get',
                url:`/order/${window.sessionStorage.getItem('orderid')}`,
            }).then(( res ) =>{
                if ( res.data.data.id !== null ){
                    this.userInfoData = res.data.data
                    this.is_existing_order_tips = true;
                }
            })
        },
        checkOrderButton() {
            this.dataRequest()
            let than = this
            function urlLinkResult (type){
                let url = new URL(than.userInfoData.pageUrl)
                if (url.pathname.includes(type)) {
                    than.$router.push(`/result/${than.userInfoData.id}${url.search}`)
                }
            }
            if ( this.userInfoData.order_status === "已完成") {
                urlLinkResult("index")
            }else{
               this.getpayurl("wechat",'')
            }
        },

        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        sessionStorage.setItem('paysuccess','1');
                        router.replace({
                            name: "paysuccess",
                            params: { id, type: data.data.data.type },
                        }).catch(() => {});
                    }
                } catch (err) {}
            }, 5000);
        },

        async getpayurl(payway, aciton) {
            
             if ( payway === '') {
                payway = this.$route.params.type === '1' ? 'alipay' : 'wechat'
             }
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
           
            
            if ( this.is_weixn ) {
                setInterval( ()=> {this.checkorder(this.userInfoData.id, 2);}, 2000)
                
            }
            let type = 2;
            this.type = type;
            let orderinfo = this.userInfoData;
            if (
                orderinfo.order_status == "已完成" ||
                orderinfo.order_status == "已支付"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({ name: "paysuccess", params: { id: this.userInfoData.id, type } }).catch(() => {});
                });
                return;
            }
            let t = this;
            setTimeout(async function() {
                await t.checkorder(t.userInfoData.id, type);
            }, 2000);
            try {
                let confirm_order_data_ = {
                    order_id: this.userInfoData.id,
                    paytype: type,
                }
                await this.confirmOrder(confirm_order_data_);
                Toast.clear()
                
                if (this.is_weixn) {
                    let payinfo = await axios.get(
                        `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${this.userInfoData.id}/wechat`)
                    let pay = payinfo.data.data;
                    console.log('res',pay)
                    wx.ready(function() {
                        wx.chooseWXPay({
                            timestamp: pay.timeStamp,
                            nonceStr: pay.nonceStr,
                            package: pay.package,
                            signType: pay.signType,
                            paySign: pay.paySign, // 支付签名
                            success: function(res) {
                                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                    router.replace({
                                        name: "paysuccess",
                                        params: { id:this.userInfoData.id, type:2 }
                                    }).catch(() => {});
                                }else{
                                    router.replace({
                                        name: "testresult",
                                        params: { id:this.userInfoData.id, type:2 }
                                    }).catch(() => {});
                                }
                            }
                        });
                    });


                }else{
                    location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${this.userInfoData.id}`;
                }
            } catch (err) {}
        },

        async confirmOrder(confirm_order_data_) {
            try {
                if ( window.localStorage.getItem('qm_red_packet_type') === null) {
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: confirm_order_data_.paytype,
                    });
                }else{
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: 2,
                        discount_id: 1
                    });
                }
                
            } catch (err) {}
        },
    }
}
</script>


<style lang="scss" scoped>
.fixed_strata_externum-chunk---{
    width: 7.5rem;
    height: 1.6rem;
}
     .fixed_top_order_chunk-2{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            left: .1rem;
            right: .1rem;
            margin: auto;
            background: #fff;
            // height: 1rem;
            font-size: .35rem;
            padding: .2rem;
            border-radius: .15rem;
            z-index: 1;
            button{
                width: 2.05rem;
                margin-top: 0.05rem;
                img{
                    display: block;
                }
            }
            .ExistingOrderTips_text_size{
                font-size: .28rem;
                margin-top: .16rem;
            }
            .button{
                width: 2.05rem;
                height: .8rem;
                border-radius: .8rem;
                color: rgb(255,236,2);
                background: red;
                border: none;
            }
        }
</style>