import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
   path: '/',
   redirect: '/index',
  },
  {
    path: '/pay/:id/:type',
    name: 'pay',
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/pay_buy_more/:id/:type',
    name: 'payBuyMore',
    component: () => import('../views/Pay_buy_more.vue')
  },
  {
    path: '/pay_/:id/:type',
    name: 'pay',
    component: () => import('../views/Pay.vue')
  },
  {
    path:'/pay/success/:id/:type', //起名支付成功页
    name:"paysuccess",
    component:() => import('../views/success.vue'),
  },
  {
    path:'/result/:id',
    name:"payresult",
    meta: { 
      keepAlive: true // 需要缓存 
    },
    component:() => import('../views/result.vue'),
  },
  {
    path:'/result/detail/:id/:nameid/:type',
    name:"resultdetail",
    component:() => import('../views/resultdetail.vue'),
  },
  {
    path:'/teacher/:id',
    name:'teacher',
    // component:() => import("../views/teacher.vue"),//弃用
    component:() => import("../views/teacherv2.vue"),
  },
  {
    path:'/teacher3/:id',
    name:'teacher3',
    component:() => import("../views/teacherv3.vue"),
  },
  {
    path:'/teacher4/:id',  //去掉宝宝
    name:'teacher4',
    component:() => import("../views/teacherv4.vue"),
  },
  // {
  //   path:'/teacherv2/:id',
  //   name:'teacher',
  //   component:() => import("../views/teacherv2.vue"),
  // },
 
  {
  	path:"/teacher/lookpre/:id/:teacherid",
  	name:"testresultpre",
  	component:()=>import("../views/testresultpre.vue")
  },
  {
    path:'/teacher/success/:id',
    name:'teachersuccess',
    component:() => import("../views/msuccess.vue"),
  },
  {
    path:'/about',
    name:'about',
    component:() => import("../views/about.vue"),
  },
  {
    path:"/plusname/:resultid/:id",
    name:"plusname",
    component:() => import("../views/plusnameResult.vue"),
    meta: { 
      keepAlive: true // 需要缓存 
    },
  },
  {
    path:'/buyplusname/:id',
    name:"buyplusname",
    component:()=>import("../views/plusnamebuy.vue")
  },{
    path:'/aitestname',
    name:"aitestname",
    // component:()=>import("../views/testname.vue")//测名页面
    component:()=>import("../views/testname_v2.vue")//测名页面
  },
  {
    path:'/aitestname/pay/:id/:type',
    name:"aitestnamepay",
    // component:()=>import("../views/testnamePay.vue")
    component:()=>import("../views/testnamePay_v2")
  },
  //v2
  {
    path:'/testname_v2',
    name:"testnameV2",
    component:()=>import("../views/testname_v2.vue")//测名页面
  },
  {
    path:'/testnamePay_v2/pay/:id/:type',
    name:"testnamePay_v2",
    component:()=>import("../views/testnamePay_v2.vue")
  },
  {
    path:'/aitestname_buy_more/pay/:id/:type',
    name:"aitestnameBuyMorePay",
    component:()=>import("../views/aitestname_buy_more.vue")
  },
  {
    path:'/aitestname/success/:id/:type', //测名支付成功页面
    name:"aitestsuccess",
    component:()=>import("../views/testpaysuccess.vue")
  },{
	path:"/testresult/:id/:type",
	name:"testresult",
	component:()=>import("../views/testresult.vue")
  }
]

const router = new VueRouter({
	mode:"history",
  routes
})

router.beforeEach((to, from, next) => {
  let query = {...to.query}

  let uniqueKey = window.localStorage.getItem('uniqueKey');
  if(uniqueKey){
    query.uniqueKey = uniqueKey
  }

  let uctrackid = window.localStorage.getItem('uctrackid');
  if(uctrackid){
    query.uctrackid = uctrackid
  }

  let querysgtr = window.localStorage.getItem('query1');

  if(query){
    let querydata = JSON.parse(querysgtr);
    query = Object.assign(query,querydata);
  }

  if (!to.query.from) {
    let fromk= window.localStorage.getItem('fromk')? window.localStorage.getItem('fromk') :10;
    query.from = to.query.from || from.query.from || fromk ;
    next({...to, query})  
  }else if(uniqueKey && !to.query.uniqueKey){
    next({...to, query})
  }else if(uctrackid && !to.query.uctrackid){
    next({...to, query})
  }else if(querysgtr && !to.query.appkey){
    next({...to, query})
  }else{
    next();
  }

})
export default router
